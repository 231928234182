import axios from 'axios'

class BaseSocket {
    connect () {
        var socket = new WebSocket(process.env.REACT_APP_API_URL + '/' + "agent1")

        socket.onopen = () => {
            socket.send('{"svc":"ready","id":"123456","data":{"accessToken":"eyJhbGciOiJSUzI1NiIsInR5..."}}')
        }
        return socket

        socket.onmessage = (msg) => {
            console.log('on message', msg)
            return JSON.parse(msg?.data)
            console.log('socket on message', JSON.parse(msg?.data))
            if (msg?.data?.awsAttendeeResp) {
                sessionStorage.setItem('attendee', msg?.data)
            }
        }

        socket.onclose = () => {
            console.log('on close')
        }			
    }
}

export default BaseSocket
