import { useMemo, useState, useEffect } from 'react'
import Router from './router'
import { Provider, useDispatch } from 'react-redux'
import store from './redux'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useFetch from 'hooks/useFetch'

export default () => {
    const [darkMode, setDarkMode] = useState(false)
    const fetchApi = useFetch()
    
    // const theme = useMemo(
    //     () => createMuiTheme({
    //         palette: {
    //             type: darkMode ? 'dark' : 'light',
    //             contrastThreshold: window._env_.APP_CONTRAST_THRESHOLD,
    //             tonalOffset: window._env_.APP_TONAL_OFFSET,
    //             primary: {
    //                 main: window._env_.APP_PRIMARY_COLOR_MAIN,
    //                 light: window._env_.APP_PRIMARY_COLOR_LIGHT,
    //                 dark: window._env_.APP_PRIMARY_COLOR_DARK
    //             },
    //         },
    //     }),
    //     [darkMode]
    // )

    const setConfig = async () => {
        var res = await fetchApi({ url: "/parameter/getparam", method: "POST", data: { parameter_id: "theme_colors" } })
        var data = res?.data?.[0]
        
        var root = document.querySelector(':root')
        root.style.setProperty('--primary', data?.primary)
        root.style.setProperty('--primary-light', data?.light_primary)
        root.style.setProperty('--primary-dark', data?.dark_primary)
        root.style.setProperty('--secondary', data?.secondary)
    }

    useEffect(() => {
        setConfig()
        console.log(process.env)
    }, [])

    return (
        <Provider store={store}>
            <Router setDarkMode={setDarkMode} />
        </Provider>
    )
}