const data = [
    "/",
    "/wakaf",
    "/detail/:id",
    "/detail",
    "/inbox",
    "/history",
    "/services",
    "/contact",
    "/faq",
    "/privacy",
    "/profile"
]

export default data